html, body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    background-color: #F5F8FA;
}

html, body, #root {
    height: 100%
}

/* Mod of: https://github.com/twbs/bootstrap/blob/v4.1.1/scss/_type.scss#L11 */

h1,h2,h3,h4,h5,h6 {
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    line-height: 1.1;
    color: inherit;
}

/*
    Plotly's notification popups aren't relevant to our users and don't match our styles.
    Unfortunately, the API does not expose a great way to hide these and this was the only suggestion on the forum
*/

div.plotly-notifier {
    display: none !important;
}
